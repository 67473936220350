import React from 'react';
import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby'; 
import { Modal, Button } from 'antd'; 
import SiteLayout from '@layouts/site-layout';
 

export default class InfoPage extends React.Component {
    

   

    render() {
        // console.log(this.props.location.search);
        const query = new URLSearchParams(this.props.location.search);
        const payment_id = query.get('payment_id')
        const status = query.get('status')
        console.log(payment_id);
        console.log(status);
        

        return (
            <SiteLayout> 
                { status === 'success'?
                <div className="top_margin_comn">
                     <div className="thank_page">
                        <h3>Congratulations!</h3>
                        <p>You have Successfully enrolled with <strong>STEPapp</strong> program. The order details are sent in your email address.</p>
                        {/* <p>Payment Id is :{payment_id} and Status is {status}</p> */}
                        {status !== null?<p>Payment Id is :{payment_id} <br/> Status : {status}</p>:'' }

                        <div className="smile_img"><img src="img/happy.png" alt="smiley" /></div>
                            <div className="footer_mess">
                                <h4 className="color_ylew">Welcome to the STEPapp team!</h4>
                                <p>We wish you a great experience to play, learn and grow with us. </p>
                                {/* <p>Order ID: #123456789 | Download your receipt <a href="#" className="color_ylew">here</a></p> */}
                                <p>For any further queries or assistance, reach us at (18002665007) / (support@stepapp.ai)</p>
                            </div>
                     </div>
                </div> :   status === 'failure'?
                 <div className="top_margin_comn">
                 <div className="thank_page">
                    <h3>Transaction failed</h3>
                    {/* <p>You have Successfully enrolled with <strong>STEPapp</strong> program. The order details are sent in your email address.</p> */}
                    {/* <p>Payment Id is :{payment_id} and Status is {status}</p> */}
                    {status !== null?<p>Payment Id is :{payment_id} and Status is {status}</p>:'' }

                    <div className="smile_img"><img src="img/happy.png" alt="smiley" /></div>
                        <div className="footer_mess">
                            <h4 className="color_ylew">Welcome to the STEPapp team!</h4>
                            <p>We wish you a great experience to play, learn and grow with us. </p>
                            {/* <p>Order ID: #123456789 | Download your receipt <a href="#" className="color_ylew">here</a></p> */}
                            <p>For any further queries or assistance, reach us at (18002665007) / (support@stepapp.ai)</p>
                        </div>
                 </div>
            </div> : 
            <div className="top_margin_comn">
            <div className="thank_page">
               <h3>Congratulations!</h3>
               <p>You have Successfully enrolled with <strong>STEPapp</strong> program. The order details are sent in your email address.</p>
               {/* <p>Payment Id is :{payment_id} and Status is {status}</p> */}
               {/* {status !== null?<p>Payment Id is :{payment_id} and Status is {status}</p>:'' } */}

               <div className="smile_img"><img src="img/happy.png" alt="smiley" /></div>
                   <div className="footer_mess">
                       <h4 className="color_ylew">Welcome to the STEPapp team!</h4>
                       <p>We wish you a great experience to play, learn and grow with us. </p>
                       {/* <p>Order ID: #123456789 | Download your receipt <a href="#" className="color_ylew">here</a></p> */}
                       <p>For any further queries or assistance, reach us at (18002665007) / (support@stepapp.ai)</p>
                   </div>
            </div>
       </div> 
                        }
            </SiteLayout>
        )
    }
}

 